<template>
  <div class="body">
    <div class="top-bg">
      <div class="flex cont">
        <div class="left">
          <div class="ewm flex">
            <img src="@/assets/img/down.png" />
          </div>
          <div class="text">扫一扫 去下载</div>
          <div class="left-btn flex">
            <img src="@/assets/img/ios.png" alt="" />
            <span>扫码下载 iOS版</span>
          </div>
          <div
            v-show="!flag"
            class="left-btn flex"
            @click="downLoad"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <img :src="androdid_show" alt="" />
            <span>扫码下载 安卓版</span>
          </div>
          <div
            v-show="flag"
            class="left-btn flex"
            @click="downLoad"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <img :src="androdid_down" alt="" style="width: 19px" />
            <span>下载 安卓 安装包</span>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/img/app_mobile.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downLoadApp } from "@/api";
export default {
  data() {
    return {
      androdid_show: require("@/assets/img/android-fill.png"),
      androdid_down: require("@/assets/img/xiazai4备份@2x.png"),
      flag: false,
    };
  },
  methods: {
    enter() {
      console.log(1);
      this.flag = true;
    },
    leave() {
      console.log(2);
      this.flag = false;
    },
    downLoad() {
      downLoadApp().then((res) => {
        if (res.data.code === 0) {
          window.location.href = res.data.data.downloadAddress;
        } else {
          this.$message("请稍后再试");
        }
      });

      //  window.location.href = "http://gfs19.atguat.net.cn/L1rthTBTxT1RCvBVdK.apk"
    },
  },
};
</script>
<style lang="scss" scoped>
.top-bg {
  background: url("~@/assets/img/bg_download.png") no-repeat center 0;
  height: 1000px;
}
.cont {
  padding-top: 272px;
  align-items: flex-start;
}
.left {
  width: 280px;
  margin-right: 199px;
  .ewm {
    width: 280px;
    height: 280px;
    background: #ffffff;
    border-radius: 16px;
    img {
      width: 251px;
      height: 251px;
    }
  }
  .text {
    height: 26px;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    margin: 16px 0 44px;
  }
  &-btn {
    width: 100%;
    height: 67px;
    border-radius: 100px;
    border: 1px solid #ffffff;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    img {
      width: 30px;
      margin-left: 28px;
      margin-right: 12px;
    }
  }
}
.right {
  width: 537px;
  height: 728px;
}
</style>